<template>
<div class="bg-kpmg-purple">
  <b-container fluid style="min-height: 90vh">
    <b-row class="text-white py-5">
      <b-col>
        <h3>Typical project timeline</h3>
      </b-col>
    </b-row>
    <b-row class="my-4 py-4 bg-white">
      <b-col>
        <div id="visualization"></div>
      </b-col>
    </b-row>
    <b-row class="my-4 py-4 text-white">
      <b-col v-for="phase in ad.timeline" :key="'phase-' + phase.id" cols="3" class="mb-4">
        <h4>{{phase.content}}</h4>
        <ol>
          <li v-for="activity in phase.activities" :key="getRandom(activity)">
            {{activity.content}}
          </li>
        </ol>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import { DataSet } from "vis-data/peer"
import { Timeline } from "vis-timeline/peer"
import "vis-timeline/styles/vis-timeline-graph2d.css"
import moment from 'moment'
import _ from 'lodash'

export default {
  components: {
  },
  computed: {
    ad: function () {
      return this.$store.state.appData
    },
    adReady: function () {
      if (this.ad.configuration) {
        return true
      }
      return false
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  mounted: function () {
    document.title = "UT - Engagement Timeline"
    this.$stat.log({ page: 'UT app start', action: 'open UT app' })
    this.timelineShow()
  },
  data () {
    return {
      timeline: null
    }
  },
  methods: {
    getRandom: function () {
      const min = 1
      const max = 9999999999999
      return Math.floor(Math.random() * (max - min) + min)
    },
    timelineShow: function () {
      if (this.timeline) {
        this.timeline.destroy()
      }
      this.showTimelineFlag = true
      // DOM element where the Timeline will be attached
      const container = document.getElementById("visualization")

      // Create a DataSet (allows two way data-binding)
      const leadTime = this.ad.timelineConfiguration.start
      const groupsData = this.ad.timelineGroups
      const timelineData = _.map(this.ad.timeline, x => {
        const item = {
          id: x.id,
          content: x.content,
          start: moment().add(leadTime + x.start, 'days'),
          group: x.group
        }
        if (x.end) {
          item.end = moment().add(leadTime + x.end, 'days')
        }
        return item
      })
      const items = new DataSet(timelineData)
      const groups = new DataSet(groupsData)

      // Configuration for the Timeline
      const options = {
        start: moment().subtract(7, 'days'),
        end: moment().add(3, 'months')
      }

      // Create a Timeline
      this.timeline = new Timeline(container, items, groups, options)
    }
  }
}
</script>

<style>
</style>
